import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"


import img1 from "../images/chauffage1.jpg"
import img2 from "../images/chauffage2.jpg"
import img3 from "../images/chauffage3.jpg"
import img4 from "../images/chauffage4.jpg"

const ChauffagePage  = () => (
    <Layout>
        <SEO title="Chauffage" />
        <section className="section">
            <div className="container">
                <h1 className="title">Chauffagistes à Mions : Air Water System</h1>
                <h2 className="subtitle">Installation de plancher chauffant et chauffage solaire : des solutions économiques dans le Rhône</h2>
                <div className="content">
                    <div class="columns">
                        <div class="column">
                            <p> 
                                Air Water System à Mions met à votre disposition des chauffagistes qui sont toujours à votre écoute.
                                <br></br>
                                Expérimentés et dynamiques, ils prennent en charge toutes les installations et entretien de vos chauffages.
                                <br></br>
                                Nous vous proposons 2 types de chauffages : 
                                <ul>
                                    <li>Le plancher chauffant</li>
                                    <li>Le chauffage solaire</li>
                                </ul>
                            </p>
                            <p>
                                Soucieux de votre satisfaction, nous vous garantissons un travail de qualité conforme aux normes en vigueur.
                            </p>
                        </div>
                        <div class="column">
                            <figure class="image">
                                <img alt="© Air Water System" src={img1}></img>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h1 className="title">Le plancher chauffant</h1>
                <div className="content">
                    <p>
                        Le plancher chauffant se compose d'un réseau de tubes encastrés dans le sol où circule l'eau chauffée à une température de 21°C à 24°C.
                        <br></br>
                        La chaleur est douce et homogène, car elle est uniformément répartie dans toutes les pièces de votre habitat.
                        <br></br>
                        Avec le plancher chauffant, la surface de votre sol est comme un grand radiateur. La chaleur est diffusée par rayonnement.
                        <br></br>
                        Le plancher chauffant étant invisible, il vous permet d'agencer vos pièces comme vous l'entendez.
                        <br></br>
                        Il y a différents types de plancher chauffant, à savoir :
                    </p>
                    <div className="columns">
                        <div className="column">
                            <h3 className="title has-text-centered">
                                Dalle à sec
                            </h3>
                            <figure class="image">
                                <img alt="© Air Water System" src={img2}></img>
                            </figure>
                        </div>
                        <div className="column">
                            <h3 className="title has-text-centered">
                                Solaire
                            </h3>
                            <figure class="image">
                                <img alt="© Air Water System" src={img3}></img>
                            </figure>
                        </div>
                        <div className="column">
                            <h3 className="title has-text-centered">
                                Rafraîchissant basse température
                            </h3>
                            <figure class="image">
                                <img alt="© Air Water System" src={img4}></img>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h1 className="title">Le chauffage solaire</h1>
                <p className="content">
                    Chauffage Solaire "Des Solutions Economiques et Ecologiques " !
                    <br></br> 
                    <br></br> 
                    À l'échelle d'une habitation individuelle ou collective, il est possible d'installer un chauffe-eau solaire, ou un chauffage solaire :
                    <br></br> 
                    Il s'agit de capteurs vitrés installés le plus souvent sur la toiture, dans laquelle circule un liquide caloporteur réchauffé par le rayonnement solaire.
                    <br></br> 
                    Ce dernier transmet ensuite la chaleur à un chauffe-eau ou à un plancher chauffant basse température.
                    <br></br> 
                    Le principe du chauffage solaire : les capteurs solaires thermiques.
                    <br></br> 
                    La température du capteur solaire grimpe lorsqu’il est exposé aux rayons du soleil.
                    <br></br> 
                    La chaleur est alors récupérée grâce à un fluide caloporteur (eau + antigel ou air), qui va s’échauffer dans un absorbeur situé sous un vitrage.
                    <br></br> 
                    Ce dernier laisse entrer la lumière du soleil et limite les pertes grâce à un rayonnement infrarouge de l’absorbeur.
                    <br></br> 
                    Les infrarouges restent prisonniers dans le capteur grâce à la vitre (effet de serre).
                    <br></br> 
                    Ce vitrage permet en plus de réduire les échanges de chaleur avec l’atmosphère.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <p className="content">
                    Pour plus de renseignements concernant l'installation ou la réparation de votre chauffage, votre plomberie ou votre climatisation,  n’hésitez pas à contacter notre entreprise Air Water System à Mions.
                </p>
            </div>
        </section>
    </Layout>
)

export default ChauffagePage;
